const SPACE_DATA = {
		"u6995525":  [{space_js: "//jffpbdmym.9fenmedia.cn/production/tonww-l/common/l/static/d-l.js", space_src: "baidujs", space_type: "inters", space_id: "u6995525"}],
		"u6995526":  [{space_js: "//jffpbdmym.9fenmedia.cn/common/u/production/poxx_m_m_eo.js", space_src: "baidujs", space_type: "native", space_id: "u6995526"}],
		"u6995527":  [{space_js: "//jffpbdmym.9fenmedia.cn/site/vqpy-y/resource/n/n/common/fr.js", space_src: "baidujs", space_type: "native", space_id: "u6995527"}],
		"u6995528":  [{space_js: "//jffpbdmym.9fenmedia.cn/site/wr-q/resource/z/static/z-oogt.js", space_src: "baidujs", space_type: "native", space_id: "u6995528"}],
};
export const SPACE_MAP = {
	Home_inter:  SPACE_DATA["u6995525"],
	Home_banner:  SPACE_DATA["u6995526"],
	Home_native:  SPACE_DATA["u6995527"],
	Home_native_center1:  SPACE_DATA["u6995528"],
	Detail_inter:  SPACE_DATA["u6995525"],
	Detail_banner:  SPACE_DATA["u6995526"],
	Detail_native:  SPACE_DATA["u6995527"],
	Detail_native_center1:  SPACE_DATA["u6995528"],
	Result_inter:  SPACE_DATA["u6995525"],
	Result_banner:  SPACE_DATA["u6995526"],
	Result_native:  SPACE_DATA["u6995527"],
	Result_native_center1:  SPACE_DATA["u6995528"],
}
export const ALL_SPACE_MAP = {
    H5_JFAI_VO_9 : {
		Home_inter:  SPACE_DATA["u6995525"],
		Home_banner:  SPACE_DATA["u6995526"],
		Home_native:  SPACE_DATA["u6995527"],
		Home_native_center1:  SPACE_DATA["u6995528"],
		Detail_inter:  SPACE_DATA["u6995525"],
		Detail_banner:  SPACE_DATA["u6995526"],
		Detail_native:  SPACE_DATA["u6995527"],
		Detail_native_center1:  SPACE_DATA["u6995528"],
		Result_inter:  SPACE_DATA["u6995525"],
		Result_banner:  SPACE_DATA["u6995526"],
		Result_native:  SPACE_DATA["u6995527"],
		Result_native_center1:  SPACE_DATA["u6995528"],
    },
    DEFAULT : {
		Home_inter:  SPACE_DATA["u6995525"],
		Home_banner:  SPACE_DATA["u6995526"],
		Home_native:  SPACE_DATA["u6995527"],
		Home_native_center1:  SPACE_DATA["u6995528"],
		Detail_inter:  SPACE_DATA["u6995525"],
		Detail_banner:  SPACE_DATA["u6995526"],
		Detail_native:  SPACE_DATA["u6995527"],
		Detail_native_center1:  SPACE_DATA["u6995528"],
		Result_inter:  SPACE_DATA["u6995525"],
		Result_banner:  SPACE_DATA["u6995526"],
		Result_native:  SPACE_DATA["u6995527"],
		Result_native_center1:  SPACE_DATA["u6995528"],
    },
};
